:root {
  color-scheme: dark;

  --color-background: #131313;
  --color-transparency-light: #FFFFFF19;
  --color-transparency-medium: #FFFFFF4D;
  --color-transparency-strong: #FFFFFF90;
  --color-note: #212121;
  --color-note-accent: white;
  --color-primary: #7b1ea2;
  --color-primary-opacity: #7B1EA267;
  --color-secondary: #69f0ae;
  --color-warn: red;

  --shadow-light: 0 0 8px #131313a8;
  --shadow-strong: 0 0 16px black;
  --shadow-overdue: 0 0 32px var(--color-warn);
  --drop-shadow-light: drop-shadow(2px 3px 6px #0000009D);
  --drop-shadow-medium: drop-shadow(2px 2px 3px #000000B2);
  --drop-shadow-strong: drop-shadow(2px 4px 3px black);
  --drop-shadow-logo: drop-shadow(0 0 16px black);

  &[theme="light"] {
    color-scheme: light;

    --color-background: white;
    --color-transparency-light: #00000019;
    --color-transparency-medium: #0000004D;
    --color-transparency-strong: #00000090;
    --color-note: white;
    --color-note-accent: black;

    --shadow-light: 0 1px 4px 1px #2F2F2F26,0 1px 2px 0 #2F2F2F26;
    --shadow-strong: 0 0 12px 0 #2F2F2F50;
    --drop-shadow-light: none;
    --drop-shadow-medium: none;
    --drop-shadow-strong: none;
    --drop-shadow-logo:
      drop-shadow(4px 0 0 black)
      drop-shadow(-4px 0 0 black)
      drop-shadow(0 4px 0 black)
      drop-shadow(0 -4px 0 black);
  }

  --width-edit-dialog: 50vw;
  --margin-edge: 24px;
  --border-radius: 12px;
  --drag-drop-transition: all cubic-bezier(0, 0, 0.2, 1) 250ms;

  --font-style: normal;
  --font-family: var(--font-family-roboto);
  --font-family-victor: 'Victor Mono', monospace;
  --font-family-roboto: 'Roboto';
}

html, body {
  overflow: hidden;

  margin: 0;
  height: 100%;
  background: var(--color-background);

  font-family: var(--font-family);
}

@media screen and (max-width: 800px) {
  html, body {
    --width-edit-dialog: 100vw;
  }
}

::selection {
  color: black;
  background-color: var(--color-secondary);
}

.hide {
  display: none;
}

.cursor {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  pointer-events: none;
  filter: blur(20px);
}
.cursor-position#position {
  position: fixed;
  margin: 0 0 4px 0;
  left: var(--margin-edge);
  color: white;
  z-index: 99999;
  opacity: .6;
  transition: bottom ease-in-out .16s;
}
@keyframes cursor-pulse {
  50% {
    filter: blur(3px);
  }
}

.mat-card {
  background: var(--color-note);
  border-radius: var(--border-radius) !important;
  transition: all ease-in-out .2s;
  .mat-card-content {
    white-space: nowrap;
  }
  .mat-card-title {
    filter: var(--drop-shadow-medium);
    margin-bottom: 1rem;
  }
}

.mat-tab-group {
  .mat-tab-header {
    width: calc(100vw - 180px - 32px);
    border: none !important;
    .mat-tab-label:last-child {
      min-width: 0;
    }
  }
  .mat-tab-body-wrapper {
    border-top: 1px solid var(--color-transparency-light);
  }
}

.windows .mat-tab-group .mat-tab-body.mat-tab-body-active {
  // noinspection CssInvalidPropertyValue
  overflow: overlay;
}
.macos .mat-tab-group .mat-tab-body.mat-tab-body-active {
  overflow: auto;
}
.linux .mat-tab-group .mat-tab-body.mat-tab-body-active {
  overflow: scroll;
}

.mat-chip.mat-standard-chip {
  background-color: #00000047;
  border-radius: var(--border-radius);
}

.mat-menu-submenu-icon {
  color: currentColor;
}

.dialog-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  margin-top: 10px;
}

.mat-badge-content {
  border-radius: var(--border-radius);
}

.mat-button-base {
  border-radius: var(--border-radius) !important;
}

.mat-dialog-container,
.mat-bottom-sheet-container,
.mat-menu-panel {
  background: var(--color-note);
  border-radius: var(--border-radius) !important;
}

.mat-checkbox {
  filter: var(--drop-shadow-medium);
  .mat-checkbox-frame {
    border-color: currentColor;
    border-radius: 4px;
  }
  .mat-checkbox-background {
    border-radius: 3px;
  }
  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: currentColor;
  }
}

.mat-menu-panel {
  min-height: 0 !important;
}

mat-dialog-container.mat-dialog-container {
  padding: 0;
  box-shadow: var(--shadow-strong);
}

div.mat-menu-content:not(:empty) {
  padding-top: 0;
  padding-bottom: 0;
}

button {
  filter: var(--drop-shadow-medium);
  &.mat-menu-item,
  &.mat-menu-item > mat-icon.mat-icon
  {
    color: inherit;
  }
}

mat-card-title.flex-container,
h1.flex-container,
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ok-button {
  background-color: var(--color-transparency-light)
}

.mat-icon-button {
  transition: all ease-in-out .2s;
  &:hover {
    transform: scale(1.3);
    opacity: 1;
  }
}

a[href] {
  color: currentColor;
}

.mat-form-field-label {
  color: currentColor;
}

.mat-snack-bar-container {
  background: var(--color-secondary);
  border: 4px solid #00000028;
  border-radius: 14px !important;
  .mat-simple-snackbar {
    font-size: larger;
  }
}

.mat-input-element:after {
  // Remove the weird height of date fields
  content: none !important;
}

.cdk-global-scrollblock {
  overflow-y: inherit;
}

.docked-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.docked-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.animated {
  animation: default-animation .3s;
}

@keyframes default-animation {
  50% {
    transform: scale(103%);
  }
}

.windows {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background: transparent;
    &:hover {
      background: var(--color-transparency-light);
    }
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: var(--color-transparency-light);
    &:hover {
      background: var(--color-transparency-medium);
    }
  }
}
